<template>
    <div class="video-container" v-if="isSplashActive">
        <video src="@/assets/tvSplash.mp4" muted autoplay />
    </div>
</template>

<script>
import { eventBus } from "@/eventBus";
export default {
    data() {
        return {
            isSplashActive: true
        }
    },
    mounted() {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";

        if (sessionStorage.getItem("splash-enabled")) {
            document.getElementsByTagName("body")[0].style.overflow = "auto";
            this.isSplashActive = false;
        }
        eventBus.$on("featured-loaded", (data) => {
            document.getElementsByTagName("body")[0].style.overflow = "auto";

            sessionStorage.setItem("splash-enabled", true);
            this.isSplashActive = false;
        });
        setTimeout(() => {
            document.getElementsByTagName("body")[0].style.overflow = "auto";
            // sessionStorage.setItem("splash-enabled", true);
            this.isSplashActive = false;
        }, 8000)

    }
}
</script>

<style lang="scss" scoped>
.video-container {
    width: 100%;
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 999;
}

@media screen and (max-width: 767px) {
    .video-container {
        img {
            width: 100vw;
        }
    }
}
</style>